import { AxiosError } from "axios";

import {
  FieldName,
  IActionRequestTrackingInfo,
  IActionResultWithBody,
  KatKinProduct,
  SubmitFieldData,
} from "@forms/schema";
import { BaseAPI } from "@utils/base-api";
import {
  SubmissionFieldValues,
} from "libs/state/src/lib/stores/useFormServiceStore";

export class FormsActionsAPI extends BaseAPI {

  public async performAction(
    actionName: string,
    actionKeyValues: SubmissionFieldValues,
    product: KatKinProduct,
    formUsed: string | undefined,
    linkingId: string | undefined,
    featureFlags: { [key: string]: string | boolean } | undefined,
    trackingInfo: IActionRequestTrackingInfo | undefined,
  ): Promise<IActionResultWithBody<unknown>> {
    const values = convertMapToRecord(actionKeyValues);
    try {
      const result = await this.axios.post("actions", {
        name: actionName,
        values,
        product,
        formUsed,
        linkingId,
        featureFlags,
        trackingInfo,
      });
      if (result.status === 400) {
        return {
          success: false,
          message: "An error occurred",
        };
      }
      if (!result.data.success) {
        return {
          ...result.data,
          success: false,
        };
      }
      return result.data;
    } catch (error) {
      if (typeof error === "object" && error instanceof AxiosError) {
        if (typeof error.response?.data === "object" && error && error?.response && error?.response?.data) {
          if ("message" in error.response.data && "failedOn" in error.response.data && "fieldName" in error.response.data.failedOn) {
            console.log("FormValidationError occurred.");
            // should set the error here?
            return {
              success: false,
              message: error.message?.toString(),
              failedOn: {
                fieldName: error.response.data.failedOn.fieldName,
              },
            };
          }
          if ("message" in error.response.data && typeof error.response.data.message === "object" && "body" in error.response.data.message) {
            // If there's a body in the message, return the body
            console.log("Error occurred.", { error: JSON.parse(error.response.data.message.body) });
            return {
              success: false,
              message: error.response.data.message.body,
            };
          }
          if ("message" in error.response.data && typeof error.response.data.message === "string") {
            console.log("Error occurred.", { error: error.response.data.message });
            return {
              success: false,
              message: error.response.data.message,
            };
          }
          return {
            success: false,
            message: error.message?.toString(),
          };
        }

        return {
          success: false,
          message: error.message,
        };
      }
      return {
        success: false,
        message: "An error occurred",
      };
    }
  }

}
export const convertMapToRecord = (
  mapToConvert: Map<FieldName, SubmitFieldData | SubmitFieldData[]>,
): Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> => {
  const newObject: Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> = {
  };
  for (const [ key, value ] of mapToConvert) {
    newObject[key] = value;
  }
  return newObject;
};
