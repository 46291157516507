"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

import { CustomerAPI } from "@api-clients/customer";
import { FreshMealPlanAPI } from "apps/website/api";
import { useAuth } from "@auth/client-sdk-react";
import { CapiClient } from "@api-clients/capi";

import { PublicConfig } from "../config/public";
import { FormsActionsAPI } from "../api/FormsActions";
import { Statistics } from "../api/Statistics";
import { CheckoutAPI } from "../api/Checkout";

export interface ApiClientsContext {
  Checkout: CheckoutAPI;
  FreshMealPlan: FreshMealPlanAPI;
  Customer: CustomerAPI;
  FormActions: FormsActionsAPI;
  Capi: CapiClient;
  Statistics: Statistics;
  tokenStatus: {
    accessToken: string | undefined;
    loggedIn: boolean | undefined;
  };
}

export const apiContext = createContext<ApiClientsContext>(undefined as unknown as ApiClientsContext);

// Site-wide context for customer data (subscriptions, cats, etc.)
export const KatkinCustomerApiProvider: FC<PropsWithChildren> = ({ children }) => {

  const { accessToken, loggedIn } = useAuth();
  const [ clients, setClients ] = useState<ApiClientsContext>({
    Checkout: new CheckoutAPI(PublicConfig.NEXT_PUBLIC_CHECKOUT_URL, accessToken),
    FreshMealPlan: new FreshMealPlanAPI(PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL, accessToken),
    FormActions: new FormsActionsAPI(PublicConfig.NEXT_PUBLIC_FORM_ACTIONS_URL, accessToken),
    Customer: new CustomerAPI(
      PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
      accessToken,
      { withCredentials: true },
    ),
    Capi: new CapiClient(
      PublicConfig.NEXT_PUBLIC_CAPI_BASE_URL,
      accessToken,
    ),
    Statistics: new Statistics(PublicConfig.NEXT_PUBLIC_STATISTICS_URL, accessToken),
    tokenStatus: {
      accessToken,
      loggedIn,
    },
  });

  useEffect(() => {
    for (const client of Object.values(clients)) {
      if (client.updateAccessToken) {
        client.updateAccessToken(accessToken);
      }
    }
    setClients({
      ...clients,
      tokenStatus: {
        accessToken,
        loggedIn,
      },
    });
    // We don't want clients as a dep, since we modify it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ accessToken, loggedIn ]);

  return (
    <apiContext.Provider value={clients}>
      { children }
    </apiContext.Provider>
  );
};
