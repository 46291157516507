import { CheckoutStatus, type CheckoutBasket } from "@/primary-models";
import { BaseAPI } from "@utils/base-api";

export class CheckoutAPI extends BaseAPI {

  async getBasket(basketId: string) {

    const response = await this.axios.get<CheckoutBasket>(`/basket/${basketId}`);

    return response.data;
  }

  async pollBasket(basketId: string): Promise<CheckoutBasket | null> {

    // Try every 2 secs for 90 seconds
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 45; i++) {

      const basket = await this.getBasket(basketId);

      if (basket.status !== CheckoutStatus.IN_PROGRESS && basket.status !== CheckoutStatus.DRAFT) return basket;

      await sleep(2000);
    }

    return null;

  }

}

const sleep = (n: number): Promise<void> => new Promise((res) => {
  setTimeout(() => {
    res();
  }, n);
});
