import { AxiosResponse } from "axios";

import type { LoginSuccessResponse } from "@auth/types";
import type { IShopifyOrder } from "@fulfilment/types";
import { BaseAPI } from "@utils/base-api";

import { PublicConfig } from "../config/public";

// APIs related to the fresh meal plans specifically
export class FreshMealPlanAPI extends BaseAPI {

  public async getCustomerFirstOrder(customerId: string, flow?: string): Promise<AxiosResponse<OrderData, unknown>> {
    return this.axios.get(
      "/customer/getOrder",
      { params: {
        katkinCustomerId: customerId,
        flow,
        force: PublicConfig.ANIKIN_ENVIRONMENT === "dev" || PublicConfig.ANIKIN_ENVIRONMENT === "stg" || PublicConfig.ANIKIN_ENVIRONMENT === "ftr",
      } },
    );
  }

}

type OrderData = {
  order: IShopifyOrder,
  authData?: LoginSuccessResponse,
  gtmData: unknown,
  gtmDataGA4: unknown,
  shopifyId?: number,
  customer_hash?: string,
  price_no_vat: string,
  catNames?: string,
  // In YYYY-MM-DD format
  deliveryDate: string,
};
